<template>
  <header class="title--container d-flex flex-column mb-3">
    <!--  ROW 1  -->
    <div class="d-flex justify-space-between align-center my-3">
      <div
        class="title__name"
      >
        {{ t('Floor plans') }}
      </div>
      <EditFloorplanButtons v-if="floors.isEditCamerasFloorEnabled" />
      <eewc-dropdown-menu
        v-if="!floors.isEditCamerasFloorEnabled"
        :menu-items="menuItems"
        :dark="false"
        :small="false"
        :disabled="!locationsStore.locations.length"
        attach-to-parent=".v-application"
        @select="value => dropDownMenuAction(value)"
      />
    </div>

    <!--  ROW 2  -->
    <div
      v-if="!floors.isEditCamerasFloorEnabled && locationsStore.locations.length"
      class="d-flex justify-space-between align-center"
    >
      <SearchComponent />
      <SelectWrappers />
    </div>
  </header>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useFloorsStore, useLocationsStore } from '@/stores';
import { t } from "@eencloud/eewc-components/src/service/locale";
import EditFloorplanButtons from '@/components/AppHeader/EditFloorplanButtons.vue';
import SearchComponent from '@/components/AppHeader/SearchComponent.vue';
import SelectWrappers from '@/components/AppHeader/SelectWrappers.vue';
import { useDeleteDialog } from './useDeleteFloorplanDialog';
import router from "@/service/router";

const floors = useFloorsStore();
const deleteDialog = useDeleteDialog();
const locationsStore = useLocationsStore();

// MENU
const menuItems = computed(() => {
  const items = [
    { name: 'Add new floor plan', disabled: false, value: 'navigateToAdd' },
    { name: 'Edit floor plan', disabled: false, value: 'navigateToEdit' },
    { name: 'Settings', disabled: false, value: 'navigateToSettings' },
    { name: 'Delete floor plan', disabled: false, value: 'navigateToDelete', warning: true }
  ];

  const filteredItems = items.filter((menuItem) => {
    switch (menuItem.value) {
      case 'navigateToEdit':
      case 'navigateToSettings':
      case 'navigateToDelete':
        return !!floors.currentFloor;
      default:
        return true;
    }
  });

  return filteredItems;
})

const dropDownMenuAction = (option: string) => {
  switch (option) {
    case 'navigateToEdit':
      floors.$patch({isEditCamerasFloorEnabled: true});
      break;
    case 'navigateToDelete':
      deleteDialog.createDeleteDialog();
    break;
    case 'navigateToAdd':
      router.push(`/new-floor-plan`);
      break;
    case 'navigateToSettings':
      floors.$patch({isEditSettingsActive: true});
      break;
  }
}

</script>
<style lang="scss">
@import "../../styles/public/main.scss";

html, body {
  overflow: hidden;
}

.title__name {
  @include subtitle-1;
  color: $primary;
}

</style>
