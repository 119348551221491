<template>
  <div class="d-flex">
    <div class="select-wrapper">
      <eewc-select-box
        :placeholder="t('Location')"
        selection-slot
        item-slot
        class="mr-2"
        :value="currentLocation"
        :items="locations.locations.map(location=> ({text: location.name, value: location.id, ...location}))"
        @input="locations.setCurrentLocationAndFloorplan"
      >
        <template #selection="{item}">
          {{ item.name }}
        </template>
        <template #item="{item}">
          {{ item.name }}
        </template>
      </eewc-select-box>
    </div>
    <div class="select-wrapper">
      <eewc-select-box
        v-if="locations.currentLocation"
        :items="floors.floors.map(floor=> ({text: floor.name, value: floor.id, ...floor}))"
        :placeholder="t('Floor')"
        :value="currentFloor"
        :disabled="!floors.floors.length"
        selection-slot
        item-slot
        @input="floors.setCurrentFloor"
      >
        <template #selection="{item}">
          {{ item.name }}
        </template>
        <template #item="{item}">
          {{ item.name }}
        </template>
      </eewc-select-box>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useFloorsStore, useLocationsStore } from '@/stores'
import { computed } from 'vue'
import { t } from '@eencloud/eewc-components/src/service/locale'

const floors = useFloorsStore()
const locations = useLocationsStore()

// Select buttons
const currentLocation = computed(() => {
  return {
    text: locations?.currentLocation?.name,
    value: locations?.currentLocation?.id,
    ...locations?.currentLocation
  }
})

const currentFloor = computed(() => {
  return {
    text: floors?.currentFloor?.name,
    value: floors?.currentFloor?.id,
    ...floors?.currentFloor
  }
})
</script>
<style lang="scss">
@import "../../styles/public/main.scss";

html, body {
  overflow: hidden;
}

.select-wrapper {
  max-width: 232px
}

</style>
