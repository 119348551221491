<template>
  <div
    class="floor-plan__page"
    v-resize="onResize"
  >
    <AppHeader />

    <div
      ref="imageElementContainer"
      class="floor-plan__wrapper"
      :class="{ 'overflow-hidden': checkIsDefaultZoomLevel }"
    >
      <div
        class="floor-plan__inner"
      >
        <div
          v-show="floorplansStore.currentFloorplanSrc"
          ref="imageWrapper"
          class="floor-plan__image-wrapper overflow-hidden"
          @dragend.prevent="dragend"
          @drop="onDropCamera($event)"
          @dragover.prevent
          @dragenter.prevent
        >
          <img
            ref="imageElement"
            :src="floorplansStore.currentFloorplanSrc"
            class="floorplan-image"
            draggable="false"
            alt=""
            @load="onLoad"
          >

          <template v-if="imageLoaded && floorsStore.currentFloorCameras">
            <camera-marker
              v-for="camera of floorsStore.currentFloorCameras || []"
              :key="camera.id"
              :camera="camera"
              :fov="camera.devicePosition.fieldOfView || 45"
              :azimuth="getCameraRotationInFloorplan(camera?.devicePosition?.azimuth, floorsStore.currentFloor, floorplansStore.currentFloorImage)"
              :range-in-meters="camera.devicePosition.rangeInMeters || 2"
              :camera-status="camera.status.connectionStatus === 'online'? 'online' : 'offline'"
              :label-position="'bottom'"
              :label="camera.name"
              :latitude="camera.floorP1ToP2PD.y"
              :longitude="camera.floorP1ToP2PD.x"
              :is-camera-edit-enabled="camera.id === (floorsStore.currentEditingCamera || {}).id"
              :image-element-height="imageElementHeight"
              :image-element-container="imageWrapper"
              :is-dragging="isDragging"
              :hoverable="!floorsStore.currentEditingCamera"
              @click="onClickCameraMarker(camera)"
              @dragstart="onDragStart($event, camera.id)"
              @changeRangeAzimuth="changeCameraRangeAzimuth(camera, $event)"
            />
          </template>
        </div>
        <eewc-loading-spinner
          :is-loading="!floorplansStore.currentFloorplanSrc || !imageLoaded || (floorplansStore.loadingFloorplans || floorsStore.loading)"
          :size="60"
        />
        <add-cameras-sidebar
          :open="floorsStore.isAddingCamerasToFloorplanActive"
          @close="closeAddingCamerasSidebar"
        />
        <settings-sidebar
          :open="floorsStore.isEditSettingsActive"
          @close="closeEditSettingsSidebar"
        />
      </div>
      <div
        v-if="floorplansStore.currentFloorplanSrc"
        class="floor-plan__controls"
      >
        <eewc-tooltip :text="t('Zoom in')">
          <template #content="{on, attrs}">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                on-image
                icon
                :icon-size="20"
                append-icon="$icon_zoom_in"
                @click="zoomIn"
              />
            </div>
          </template>
        </eewc-tooltip>
        <eewc-tooltip :text="t('Zoom out')">
          <template #content="{on, attrs}">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                on-image
                icon
                :icon-size="20"
                append-icon="$icon_zoom_out"
                @click="zoomOut"
              />
            </div>
          </template>
        </eewc-tooltip>
        <eewc-tooltip :text="t('Fullscreen')">
          <template #content="{on, attrs}">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                v-if="appStore.fullScreenElRef"
                class="hide-on-fullscreen"
                on-image
                icon
                :icon-size="20"
                append-icon="$icon_full_size"
                @click="goFullScreen(appStore.fullScreenElRef)"
              />
            </div>
          </template>
        </eewc-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore, useCamerasStore, useFloorplansStore, useFloorsStore, useLocationsStore } from '@/stores'
import { getCameraRotationInFloorplan, getCameraRotationInMap, getCursorCoordinates } from '@/service/FloorplanFormulas'
import SettingsSidebar from '@/components/SettingsSidebar.vue'
import AddCamerasSidebar from '../FloorPlan/AddCamerasSidebar.vue'
import { t } from '@eencloud/eewc-components/src/service/locale'
import { computed, nextTick, ref, watch } from 'vue'
import { ApiCameraWithIncludes, FloorResponse } from '@eencloud/eewc-components/src/service/api-types'
import AppHeader from '@/components/AppHeader/AppHeader.vue'
import { goFullScreen } from '@/service/helpers'
import { useZoom } from '@/components/floorplanZoomHook'
import CameraMarker from '@/components/CameraMarker.vue'

const floorsStore = useFloorsStore();
const floorplansStore = useFloorplansStore();
const camerasStore = useCamerasStore();
const locationsStore = useLocationsStore();
const appStore = useAppStore();

const imageElementContainer = ref();
const imageWrapper = ref();

const imageLoaded = ref<boolean>(false);
const droppedOverFloorplan = ref<boolean>(false);
const imageElement = ref<HTMLImageElement>();
const imageElementHeight = ref(imageElement.value?.height);
const isDragging = ref(false);
let draggingElementCopy: HTMLElement | undefined = undefined;

function onClickCameraMarker(camera: ApiCameraWithIncludes) {
  if (floorsStore.isEditCamerasFloorEnabled) {
    const isClickedCameraEditing = camera.id === (floorsStore.currentEditingCamera || {}).id;

    floorsStore.$patch({
      currentEditingCamera: isClickedCameraEditing ? undefined : camera
    })
  }
}

const { zoomIn, zoomOut, updateDimensions, zoomLevel } = useZoom(imageElement, imageElementContainer);

const checkIsDefaultZoomLevel = computed(() => {
  return zoomLevel.value === 1

})

watch(
  () => zoomLevel.value,
  () => {
    imageElementHeight.value = imageElement.value ? imageElement.value.height : imageElementContainer.value.height
  }
);

function onLoad() {
  updateDimensions();
  imageElementHeight.value = imageElement.value ? imageElement.value.height : imageElementContainer.value.height;
  imageLoaded.value = true;
}



function getCameraCoordinates(dragEvent: DragEvent, floorplanImageWrapperEl: Element, floor: FloorResponse) {
  const floorplanPositionRelativeToWindow = floorplanImageWrapperEl.getBoundingClientRect();
  const dropPositionRelativeToFloorplan = {
    x: dragEvent.clientX - floorplanPositionRelativeToWindow.x,
    y: dragEvent.clientY - floorplanPositionRelativeToWindow.y
  }
  const dropPositionRelativeToFloorplanPercentage = {
    x: dropPositionRelativeToFloorplan.x / floorplanPositionRelativeToWindow.width,
    y: dropPositionRelativeToFloorplan.y / floorplanPositionRelativeToWindow.height,
  }

  if(!floorsStore.currentFloor) {
    return
  }

  return getCursorCoordinates(
    floor,
    dropPositionRelativeToFloorplanPercentage,
    floorplansStore.currentFloorImage
  );
}
function dragend() {
  isDragging.value = false;
  if (droppedOverFloorplan.value) {
    return;
  }

  if (draggingElementCopy) {
    draggingElementCopy.remove();
  }
}

function getRoundedCoordinate(number: number) {
  return Number(number.toFixed(8));
}

async function onDropCamera(dragEvent: DragEvent) {
  droppedOverFloorplan.value = true;

  if (!floorsStore.currentFloor ||
    !locationsStore.currentLocation ||
    !imageWrapper.value ||
    !dragEvent?.dataTransfer?.getData('camera')) {
    return;
  }

  appStore.loading = true;

  const cameraCopy: ApiCameraWithIncludes = JSON.parse(dragEvent.dataTransfer.getData('camera'));
  const cameraCoordinates = getCameraCoordinates(dragEvent, imageWrapper.value, floorsStore.currentFloor);
  let virtualScroll = document.querySelector('.virtual-scroll');
  const lastScrollLevel = virtualScroll?.scrollTop || 0;

  try {
    if (floorsStore.isAddingCamerasToFloorplanActive) {
      await camerasStore.editCamera({
        cameraId: cameraCopy.id,
        cameraData: {
          locationId: locationsStore.currentLocation.id
        },
        devicePosition: {
          longitude: getRoundedCoordinate(cameraCoordinates?.x),
          latitude: getRoundedCoordinate(cameraCoordinates?.y),
          floor: floorsStore.currentFloor.floorLevel
        }
      })
    } else {
      await camerasStore.editCamera({
        cameraId: cameraCopy.id,
        devicePosition: {
          longitude: getRoundedCoordinate(cameraCoordinates?.x),
          latitude: getRoundedCoordinate(cameraCoordinates?.y),
        }
      })
    }
  } catch (e) { /* empty */ }

  draggingElementCopy?.remove();

  droppedOverFloorplan.value = false;
  appStore.loading = false;
  appStore.$patch({ addCamSidebarKey: appStore.addCamSidebarKey+1 });

  await nextTick(()=> {
    virtualScroll = document.querySelector('.virtual-scroll')
    if(virtualScroll?.scrollTo){
      virtualScroll.scrollTo(0, lastScrollLevel);
    }
  });
}

function closeAddingCamerasSidebar() {
  floorsStore.$patch({ isAddingCamerasToFloorplanActive: false });
}
function closeEditSettingsSidebar() {
  floorsStore.$patch({ isEditSettingsActive: false });
}

function onDragStart(event: DragEvent, cameraId: string) {
  const applicationEl = document.getElementsByClassName('v-application')[0] as HTMLElement;

  isDragging.value = true;
  const oldDraggingElement = document.querySelector('.camera-marker-dragging');

  if (oldDraggingElement) {
    oldDraggingElement.remove();
  }

  draggingElementCopy = document.getElementsByClassName(`camera-marker-${cameraId}`)[0].cloneNode(true) as HTMLElement;
  draggingElementCopy.classList.add('camera-marker-dragging');
  draggingElementCopy.style.left = `${event.pageX}px`;
  draggingElementCopy.style.top = `${event.pageY}px`;

  applicationEl.appendChild(draggingElementCopy);

  imageWrapper.value.ondragover = function(evt: DragEvent) {
    if (!draggingElementCopy) {
      return;
    }

    draggingElementCopy.style.left = `${evt.pageX}px`;
    draggingElementCopy.style.top = `${evt.pageY}px`;
  }
}

async function changeCameraRangeAzimuth(camera: ApiCameraWithIncludes, data: { azimuth: number, rangeInMeters: number }) {
  appStore.loading = true;

  if(!floorsStore.currentFloor){
    return
  }

  const cameraRotationInMap = getCameraRotationInMap(
    data.azimuth,
    floorsStore.currentFloor,
    floorplansStore.currentFloorImage
  )

  try{
    await camerasStore.editCamera({
      cameraId: camera.id,
      devicePosition: {
        azimuth: getRoundedCoordinate((cameraRotationInMap + 360) % 360),
        rangeInMeters: data.rangeInMeters
      }
    })
  } catch (error) { /* empty */ }

  appStore.loading = false;
}

function onResize() {
  updateDimensions();
  imageElementHeight.value = imageElement.value ? imageElement.value.height : imageElementContainer.value.height;
}

</script>

<style lang="scss" scoped>
@import "../../styles/public/main.scss";
.button_group {

}
.floor-plan {
  &__page {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
  }

  &__wrapper {
    display: flex;
    overflow: auto;
    justify-content: center;
    max-height: 100%;
    text-align: center;
    height: 100%;
  }

  &__inner {
    max-height: 100%;
    max-width: 100%;
  }

  &__popup {
    $triangleHeight: 7px;
    $markerDotHeight: 12px;
    position: absolute;
    overflow: visible;
    top: -$triangleHeight;
    left: 0;
    z-index: 5;
    max-height: 250px;
    max-width: 250px;
    background: white;
    border-radius: 4px;
    padding: 4px;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    transform: translateX(-50%) translateY(-100%);
    cursor: pointer;
    &__preview {
      position: relative;
      width: 242px;
      max-height: 214px;
      height: 100%;
      display: flex;
    }
    &__info {
      padding: 4px 8px;
      width: 100%;
      &--name {
        @include subtitle-2;
      }
    }
    &__triangle {
      width: 0;
      height: 0;
      border-left: $triangleHeight solid transparent;
      border-right: $triangleHeight solid transparent;
      border-top: $triangleHeight solid white;
      position: absolute;
      bottom: 100%;
      left: calc(50% - $triangleHeight);
      z-index: 5;
    }
    
    &__overlay {
      width: 100%;
      top: 0;
      position: absolute;
      background-color: transparent;
      border-radius: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      height: 72px;
      background: linear-gradient(180deg, #212a34 0%, rgba(33, 42, 52, 0) 100%);
      padding: 8px;
      .overlay-data {
        height: 24px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        color: white !important;

        &__date {
          @include subtitle-2;
        }

        &__actions {
          display: flex;
          align-items: center;
          height: fit-content;
        }
      }
    }

    &--on-bottom {
      transform: translateX(-50%) translateY($markerDotHeight);
      top: $triangleHeight;
      ~ .floor-plan__popup__triangle {
        bottom: -$triangleHeight;
        transform: rotate(180deg);
      }
    }
  }

  &__controls {
    position: absolute;
    z-index: 4;
    display: grid;
    gap: 12px;
    right: 0;
    bottom: 0;
  }

  &__image-wrapper {
    position: relative;
    display: block;
    user-select: none;

    img {
      filter: grayscale(1);
      user-select: none;
    }
  }
}
</style>
