<template>
  <eewc-searchbar
    v-model="searchFloorplan"
    :placeholder="t('Search for floor plans and cameras')"
    :collections="searchCollection || []"
    :no-result="t('No results found')"
  />
</template>
<script setup lang="ts">
import { ref, computed, ComputedRef } from 'vue'
import { Collection } from '@eencloud/eewc-components/src/components/text-inputs/searchBar/types'
import { ApiCameraWithIncludes, FloorResponse } from '@eencloud/eewc-components/src/service/api-types'
import router from '@/service/router'
import {useFloorsStore, useLocationsStore } from '@/stores'
import { t } from "@eencloud/eewc-components/src/service/locale";

const floors = useFloorsStore()
const locations = useLocationsStore()

const searchFloorplan = ref<string>('');

const searchCollection: ComputedRef<Collection[]> = computed(() => {
  return [
    {
      items: floors.floors || [],
      typeName: 'Floorplan',
      typeIcon: '$icon_floorplan',
      callback: async function(value) {
        await floors.setCurrentFloor(value as FloorResponse)
      }
    },
    {
      items: floors.currentFloorCameras || [],
      typeName: 'Camera',
      typeIcon: '$icon_camera',
      callback: function(value) {
        redirectToLiveView(value as ApiCameraWithIncludes)
      }
    }
  ]
})

const redirectToLiveView = function (camera: ApiCameraWithIncludes) {
  const locationId = locations.currentLocation?.id
  const floorId = floors.currentFloor?.id
  const routeCameraId = router.currentRoute.params.cameraId
  if(routeCameraId === camera.id) return
  router.push({ 'path': `/location/${locationId}/floor_plan/${floorId}/video/${camera.id}` })
}

</script>
<style lang="scss">
@import "../../styles/public/main.scss";

html, body {
  overflow: hidden;
}

</style>
