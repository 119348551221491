import {reactive, onMounted, nextTick, ref} from 'vue';

export function useZoom(element: any, container: any) {
  const zoomLevel = ref(1);
  const minLevel = 1;
  const maxLevel = 5;
  let defaultWidth = '';
  let defaultHeight = '';
  const defaultPercentageCoords = {
    left: 0.5,
    top: 0.5
  }
  const lastPercentageCoords = {
    left: 0,
    top: 0
  }

  const zoomState = reactive({
    element,
    container,
  })

  function updateDimensions() {
    const { currentScrollLeft, currentScrollTop, availableScrollWidth, availableScrollHeight } = getScrollInfo(zoomState.container);
    setLastPercentageCoords(currentScrollLeft, currentScrollTop, availableScrollWidth, availableScrollHeight);
    setZoomState();
  }

  function zoomIn() {
    if (zoomLevel.value >= maxLevel) {
      return;
    }
    zoomLevel.value += 1;
    if (zoomLevel.value > 1) {
      updateDimensions();
    }
  }

  function zoomOut() {
    if (zoomLevel.value <= minLevel) {
      lastPercentageCoords.left = defaultPercentageCoords.left;
      lastPercentageCoords.top = defaultPercentageCoords.top;
      return;
    }
    zoomLevel.value -= 1;
    if (zoomLevel.value >= 1) {
      updateDimensions();
    }
  }

  function setLastPercentageCoords(
    currentScrollLeft: number,
    currentScrollTop: number,
    availableScrollWidth: number,
    availableScrollHeight: number
  ) {
    if (!isNaN(lastPercentageCoords.top) && !isNaN(currentScrollTop)) {
      lastPercentageCoords.top = currentScrollTop / availableScrollHeight;
    } else {
      lastPercentageCoords.top = defaultPercentageCoords.top;
    }

    if (!isNaN(lastPercentageCoords.left) && !isNaN(currentScrollLeft)) {
      lastPercentageCoords.left = currentScrollLeft / availableScrollWidth;
    } else {
      lastPercentageCoords.left = defaultPercentageCoords.left;
    }
  }

  function setZoomState() {
    const containerClientWidth = zoomState.container.clientWidth;
    const containerClientHeight = zoomState.container.clientHeight;
    const imageNaturalWidth = zoomState.element.naturalWidth;
    const imageNaturalHeight = zoomState.element.naturalHeight;

    const imageAspectRatio = imageNaturalWidth / imageNaturalHeight;

    let imageWidth = imageNaturalWidth;
    let imageHeight = imageNaturalHeight;

    if (imageWidth > containerClientWidth) {
      imageWidth = containerClientWidth;
      imageHeight = containerClientWidth / imageAspectRatio;
    }

    if (imageHeight > containerClientHeight) {
      imageHeight = containerClientHeight;
      imageWidth = containerClientHeight * imageAspectRatio;
    }

    zoomState.element.style.width = imageWidth * zoomLevel.value + "px";
    zoomState.element.style.height = imageHeight * zoomLevel.value + "px";

    zoomState.element.parentElement.style.width = imageWidth * zoomLevel.value + "px";
    zoomState.element.parentElement.style.height = imageHeight * zoomLevel.value + "px";

    const newAvailableScrollWidth = zoomState.container.scrollWidth - containerClientWidth;
    const newAvailableScrollHeight = zoomState.container.scrollHeight - containerClientHeight;

    zoomState.container.scrollTo(
      lastPercentageCoords.left * newAvailableScrollWidth,
      lastPercentageCoords.top * newAvailableScrollHeight
    );
  }

  function getScrollInfo(zoomStateContainer: HTMLElement) {
    return {
      currentScrollLeft: zoomStateContainer.scrollLeft,
      currentScrollTop: zoomStateContainer.scrollTop,
      availableScrollWidth: zoomStateContainer.scrollWidth - zoomStateContainer.clientWidth,
      availableScrollHeight: zoomStateContainer.scrollHeight - zoomStateContainer.clientHeight
    }
  }

  onMounted(async () => {
    defaultWidth = element.value.style.width;
    defaultHeight = element.value.style.height;

    await nextTick();
    if(zoomState.container) {
      const initialCoords = {x: 0, y: 0};
      let isPanning = false;

      const getXY =({ clientX, clientY }: { clientX: number, clientY: number }) => {
        const containerRect = zoomState.container.getBoundingClientRect();
        return {
          x: clientX - containerRect.left,
          y: clientY - containerRect.top,
        }
      }

      const panStart = (ev: MouseEvent) => {
        if (zoomLevel.value === 1) {
          return;
        }
        isPanning = true;
        const { x, y } = getXY({ clientX: ev.clientX, clientY: ev.clientY });
        initialCoords.x = zoomState.container.scrollLeft + x;
        initialCoords.y = zoomState.container.scrollTop + y;
      }

      const panMove = (ev: MouseEvent) => {
        if (!isPanning) return;
        const { x, y } = getXY(ev);
        zoomState.container.scrollTo(initialCoords.x - x,  initialCoords.y - y);
      }

      const panEnd = () => {
        isPanning = false;
      }

      zoomState.container.addEventListener("mousedown", panStart);
      document.addEventListener("mousemove", panMove);
      document.addEventListener("mouseup", panEnd);
    }
  });

  return { zoomIn, zoomOut, updateDimensions, zoomLevel };
}
