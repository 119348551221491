import { defineStore } from 'pinia'
import { ref, } from 'vue'

export const useAppStore = defineStore('app', () => {
  const isNewWebapp = ref<boolean>(false);
  const loading = ref<boolean>(true);
  const isEditActive = ref<boolean>(false);
  const fullScreenElRef = ref<Element>();
  const addCamSidebarKey = ref<number>(0);
  const showCameraNames = ref<boolean>((() => {
    const defaultValue = true;
    const value = localStorage.getItem('showCameraNames')
    return value === null ? defaultValue : value === 'true'
  })())
  const showCameraFOV = ref<boolean>((() => {
    const defaultValue = true;
    const value = localStorage.getItem('showCameraFOV')
    return value === null ? defaultValue : value === 'true'
  })())

  return {
    isNewWebapp,
    loading,
    isEditActive,
    fullScreenElRef,
    showCameraNames,
    addCamSidebarKey,
    showCameraFOV
  }
})
