import axios from 'axios';
import { rerouteToOAuthLogin } from '@eencloud/eewc-components/src/service/auth.js'
import { useMessagingStore } from '@/stores';

export default function setupInterceptor() {
  // axios.interceptors.request.use(
  //   function(config) {
  //     // console.log(config);
  //     return config;
  //   },
  //   function(err) {
  //     return Promise.reject(err);
  //   }
  // );

  
  axios.interceptors.response.use(
    undefined,
    async function(err) {
      if(err.response?.config?.method === 'get' && process.env.NODE_ENV === 'production') return;
      // TEMPORARY FIX FOR SPEAKERS NOT ACCEPTING JWT
      if(!err.response || ['/speakers', '/media/recordedImage.jpeg'].includes(err.response?.config.url)) return;
      if(err.response?.config.url.indexOf("/accounts/self") !== -1) {
        checkAccount(err.response);
        return;
      }

      const messagingStore = useMessagingStore()
      if(err.response.status === 401) {
        window.$cookies.remove('vms_auth_key');
        window.$cookies.remove('auth_key');
        await rerouteToOAuthLogin()
        return;
      }
      const e = err.response.data;
      messagingStore.addNotification(
        {
          iconColor: "negative",
          icon: "$icon_attention",
          title: "Something went wrong",
          body: e.message,
        }
      );
      return Promise.reject(err);
    }
  );

  function checkAccount(response) {
    if(response.data.code === 403 && response.data.status === 'permissionDenied') {
      // const accountsStore = useAccountStore();
      // accountsStore.updateAccountPermissionDeniedStatus(true);
    }
  }
}