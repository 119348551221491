import { defineStore } from "pinia";
import { ref } from "vue";

type DialogType = {
  showDialog: boolean,
  icon?: string,
  iconColor?: string,
  titleSlot?: string,
  bodySlot?: string,
  id?: string,
  confirmButtonCallback?(): void,
  buttonMessage?: string,
  name?: string
}

type NotificationType = {
  iconColor: string,
  icon: string,
  title: string,
  body?: string,
}

export const useMessagingStore = defineStore(
  'messaging',
  function() {
    const confirmationDialog = ref<DialogType>({ showDialog: false });
    const notification = ref<NotificationType | null>(null);

    function deleteDialog(payload: { type: string, deleteCallback(): Promise<void>, item: { id: string, name: string } }, dialogText: {title: string, body: string, buttonMessage: string}) {
      confirmationDialog.value = {
        showDialog: true,
        icon: "$icon_delete",
        iconColor: "negative",
        titleSlot: dialogText.title,
        bodySlot: dialogText.body,
        id: payload.item.id,
        confirmButtonCallback: payload.deleteCallback,
        buttonMessage: dialogText.buttonMessage,
        name: payload.item.name
      };
    }

    function resetConfirmationDialog() {
      confirmationDialog.value = { showDialog: false };
    }

    function addNotification(notificationToSet: NotificationType) {
      notification.value = notificationToSet;
      
      setTimeout(() => {
        notification.value = null;
      }, 10000)
    }
    
    function removeNotification() {
      notification.value = null;
    }

    return {
      confirmationDialog,
      notification,
      deleteDialog,
      resetConfirmationDialog,
      addNotification,
      removeNotification,
    }
  }
)