<template>
  <div class="camera-states">
    <v-icon
      :size="`${isBig ? 80 : 48}`"
      :class="{'mb-2': showTime}"
    >
      {{ statusContent(status).icon }}
    </v-icon>
    <div
      v-if="showTime"
      :class="`camera-states__time ${isBig && 'camera-states__time--big'}`"
    >
      {{ statusContent(status).text }}
    </div>
  </div>
</template>

<script>
import { t } from "@eencloud/eewc-components/src/service/locale";

export default {
  props: {
    status: { type: String, required: true },
    isBig: { type: Boolean },
    showTime: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    function statusContent(connectionStatus) {
      let statusObj;
      switch(connectionStatus) {
        case 'off':
          statusObj = {
            icon: '$icon_off', text: t("Turned off")
          }
          break;
        case 'deviceOffline':
          statusObj = {
            icon: '$icon_offline', text: t("Offline")
          }
          break;
        case 'internetOffline':
          statusObj = {
            icon: '$icon_internet_offline', text: t("Internet offline")
          }
          break;
        default:
          statusObj = {
            icon: '$icon_help', text: t("Status unknown")
          }
          break;
      }

      return statusObj;
    }

    return { statusContent }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/public/main.scss";

.camera-states {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &__time {
    @include body-2;
    color: $primaryWhite;
    text-align: center;
    &--big {
      @include body-1;
      text-align: center;
    }
  }
}
</style>
