import { ref, reactive, onMounted, nextTick } from 'vue';

export function useZoom(element: HTMLElement, container: HTMLElement) {
  const level = ref<number>(1);
  const minLevel = 1;
  const maxLevel = 5;
  let rect: DOMRect | null = null;

  const zoomState = reactive({
    element,
    container,
  })

  function changeDimensions() {
    if (!rect) rect = zoomState.element.getBoundingClientRect();

    if(rect){
      zoomState.element.style.width = level.value > minLevel ? rect.width * level.value + "px" : "100%";
      zoomState.element.style.height = level.value > minLevel ? rect.height * level.value + "px" : "100%";

      zoomState.container.scrollTo(
        (zoomState.container.scrollWidth / 2) - (zoomState.container.clientWidth / 2),
        (zoomState.container.scrollHeight / 2) - (zoomState.container.clientHeight / 2)
      );
    }
  }

  function zoomIn() {
    if (level.value >= maxLevel) {
      return;
    }
    level.value += 1;
    if (level.value > 1) {
      changeDimensions();
    }
  }

  function zoomOut() {
    if (level.value <= minLevel) {
      return;
    }
    level.value -= 1;
    if (level.value >= 1) {
      changeDimensions();
    }
  }

  onMounted(async () => {
    await nextTick();
    if(zoomState.container) {
      const initialCoords = {x: 0, y: 0};
      let isPanning = false;
  
      const getXY =({clientX, clientY}: { clientX: number, clientY: number }) => {
        const containerRect = zoomState.container.getBoundingClientRect();
        return {
          x: clientX - containerRect.left,
          y: clientY - containerRect.top,
        }
      }
  
      const panStart =(ev: MouseEvent) => {
        ev.preventDefault();
        isPanning = true;
        const { x, y } = getXY({ clientX: ev.clientX, clientY: ev.clientY });
        initialCoords.x = zoomState.container.scrollLeft + x;
        initialCoords.y = zoomState.container.scrollTop + y;
      }
  
      const panMove =(ev: MouseEvent) => {
        if (!isPanning) return; // Do nothing
        const { x, y } = getXY(ev);
        zoomState.container.scrollTo(initialCoords.x - x,  initialCoords.y - y);
      }
  
      const panEnd = () => {
        isPanning = false;
      }
  
      zoomState.container.addEventListener("mousedown", panStart);
      document.addEventListener("mousemove", panMove);
      document.addEventListener("mouseup", panEnd);
    }
  });

  return { zoomIn, zoomOut };
}