import { useCamerasStore, useFloorsStore, useLocationsStore } from '@/stores'

export const MessageManager = function () {
  const self = this;
  const camerasStore = useCamerasStore();
  const locationsStore = useLocationsStore();
  const floorsStore = useFloorsStore();
  
  window.addEventListener('message', function (event) {
    if (typeof event.data === 'string') {
      const messageData = JSON.parse(event.data)
      self.resolveMessage(messageData)
    }
  })

  self.postMessage = function (to, action, data) {
    if (!to || !action) {
      return
    }

    to.postMessage(JSON.stringify({
      action: action,
      data: data
    }), '*')
  }

  self.resolveMessage = function (messageData) {
    switch (messageData.action) {
      case 'fetchPreloadedCameras':
        camerasStore.$patch({
          cameras: messageData.data
        })
        locationsStore.setCurrentLocationCameras()
        floorsStore.setCurrentFloorCameras()
        break

      case 'fetchPreloadedCamerasStatuses':
        camerasStore.$patch((store) => {
          const currentCameras = new Map(store.cameras.map((camera) => [camera.id, camera]));
          const updatedCameras = messageData.data;
          
          updatedCameras.forEach((updatedCamera) => {
            const currentCamera = currentCameras.get(updatedCamera.id);
            currentCamera.status = updatedCamera.status;
          })

          store.cameras = Array.from(currentCameras.values())
        })
        locationsStore.setCurrentLocationCameras()
        floorsStore.setCurrentFloorCameras()
        break
    }
  }
}
