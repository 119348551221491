import api from "@eencloud/eewc-components/src/service/api";
import { ApiFeedWithIncludes } from "@eencloud/eewc-components/src/service/api-types";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useFeedsStore = defineStore(
  'feeds',
  function () {
    const feeds = ref()
    feeds.value = {};

    async function getPreviewFeed(deviceId: string) {
      return await getFeed(deviceId, "preview")
    }

    async function getMainFeed(deviceId: string) {
      return await getFeed(deviceId, "main")
    }

    async function getTalkDownFeed(deviceId: string) {
      return await getFeed(deviceId, "talkdown")
    }

    async function getFeed(deviceId: string, type: "main"|"preview"|"talkdown") {
      const targetId = deviceId + "-" + type
      try {
        const foundFeedObj: ApiFeedWithIncludes = feeds.value[targetId];
        if (foundFeedObj) {
          return foundFeedObj
        } else {
          const data = await api.getFeeds({ deviceId })
          let foundFeedObj;
          if (data && data.results) {
            data.results.forEach(result => {
              feeds.value[result.id] = result;
              if (result.id === targetId) {
                foundFeedObj = result
              }
            });
          }
          return foundFeedObj;
        }
      } catch (error) {
        console.error(error)
      }
    }

    async function fetchAllFeeds() {
      try {
        const data = await api.getFeeds({});
        if (data && data.results) {
          data.results.forEach(result => {
            feeds.value[result.id] = result;
          });
        }
      } catch (error) {
        console.error(error)
      }
    }



    return {
      feeds,
      getPreviewFeed,
      getMainFeed,
      fetchAllFeeds,
      getTalkDownFeed
    }
  }
);