import { Loader } from "@googlemaps/js-api-loader";
// @ts-ignore
import google from '@types/google.maps';
import { Result, CustomElements } from '@/service/googleMaps/googleMapsTypes';

export default new Loader({
  apiKey: 'AIzaSyC-KKl7X86cCpqrrJO5m0rzw-TOHK9nA3I',
  version: 'weekly',
  libraries: ['geometry', 'places']
});

export const loadGoogle = async function () {
  const google = await new Loader({
    apiKey: 'AIzaSyC-KKl7X86cCpqrrJO5m0rzw-TOHK9nA3I',
    version: 'weekly',
    libraries: ['geometry', 'places']
  }).load()

  window.googleInstance = google
}

export const createHiddenMap = async function () {
  const element = document.createElement('div')
  element.style.visibility = 'hidden'
  element.id = 'hiddenMap'
  document.body.appendChild(element)

  window.hiddenMap = await (new GoogleMap()).init({ mapNode: element })
}

export const createMap = async function(element: HTMLElement, customElements: CustomElements) {
  return await (new GoogleMap()).init({
    mapNode: element,
    customElements: customElements
  })
}

export class GoogleMap {
  public google: google;
  public map: google.maps.Map;
  public customElements?: CustomElements;

  private defaultOptions = {
    // default set Austin, Texas
    center: {
      lat: 30.269501,
      lng: -97.715942
    },
    restriction: {
      latLngBounds: {
        north: 85,
        south: -85,
        west: -180,
        east: 180
      }
    },
    zoom: 2,
    minZoom: 2,
    tilt: 0,
    gestureHandling: "greedy",
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    rotateControl: false,
  }

  public async init (arg: {mapNode: HTMLElement, options?: google.maps.MapOptions, customElements?: CustomElements}) {
    const self = this
    self.google = window.googleInstance || await loadGoogle();
    self.customElements = arg.customElements;

    await (new Promise(function (resolve) {
      self.map = new self.google.maps.Map(arg.mapNode, {
        ...self.defaultOptions, ...arg.options
      })
      self.google.maps.event.addListenerOnce(self.map, 'projection_changed', function () {
        resolve(null)
      })
    }))

    if (self.customElements?.zoomIn && self.customElements.zoomOut) {
      self.customElements.zoomIn.addEventListener("click", () => {
        self.map.setZoom(self.map.getZoom() + 1);
      });

      self.customElements.zoomOut.addEventListener("click", () => {
        self.map.setZoom(self.map.getZoom() - 1);
      });
    }

    if (self.customElements?.overview) {
      self.customElements.overview.addEventListener("click", () => {
        const mapTypeId = self.map.getMapTypeId();

        switch (mapTypeId) {
          case 'satellite':
          case 'hybrid':
            self.map.setMapTypeId("roadmap");
            if (self.customElements?.mapToggle) {
              self.customElements.mapToggle.value = false;
            }
            break;
          default:
            self.map.setMapTypeId("hybrid");
            if (self.customElements?.mapToggle) {
              self.customElements.mapToggle.value = true;
            }
        }
      });
    }

    return self
  }
}

export class PlacesService {
  public google: google;
  public placesService: google.maps.places.PlacesService;
  private statuses: google.maps.places.PlacesServiceStatus
  constructor(google: google) {
    this.google = google;
    this.placesService = new this.google.maps.places.PlacesService(document.createElement('div'));
    this.statuses = google.maps.places.PlacesServiceStatus;
    return this;
  }

  async searchPlaceFromQuery(address: string) {
    const self = this;
    return new Promise(function (resolve, reject){
      const request = {
        query: address,
        fields: ['name', 'geometry'],
      };

      self.placesService.findPlaceFromQuery(request, function(
        results: google.maps.places.PlaceResult,
        status: google.maps.places.PlacesServiceStatus
      ) {
        switch (status){
          case self.statuses.OK:
            resolve(results[0]?.geometry?.location);
            break;
          default:
            reject()
        }
      });
    });
  }
}

export class AutocompleteService {
  public google: google;
  public autocompleteService: google.maps.places.AutocompleteService;
  private statuses: google.maps.places.PlacesServiceStatus

  constructor (google: google) {
    this.google = google;
    this.autocompleteService = new this.google.maps.places.AutocompleteService();
    this.statuses = google.maps.places.PlacesServiceStatus;
  }

  private mapResults(results: [google.maps.places.AutocompletePrediction]) {
    return results.map(function(result): Result {
      return {
        name: result.description,
        id: result.place_id,
      }
    });
  }

  public getPlacePredictionsFromGoogle(input: string) {
    const self = this;
    return new Promise(function (resolve, reject){
      const request = {
        input: input,
      };

      self.autocompleteService.getPlacePredictions(request, function(
        results: google.maps.places.AutocompletePrediction,
        status: google.maps.places.PlacesServiceStatus
      ) {
        switch (status){
          case self.statuses.OK:
            resolve(self.mapResults(results));
            break;
          case self.statuses.NOT_FOUND:
          case self.statuses.ZERO_RESULTS:
            resolve([]);
            break;
          default:
            reject();
        }
      }).then(function (){
        // empty
      }).catch(function(err: any) {
        reject(err)
      });
    });
  }
}
