import api from "@eencloud/eewc-components/src/service/api";
import {
  ApiCameraWithIncludes,
  FloorResponse,
  LocationGet
} from '@eencloud/eewc-components/src/service/api-types'
import { defineStore } from "pinia";
import { ref } from 'vue'
import { useCamerasStore } from '@/stores/cameras'
import {useFloorsStore} from '@/stores/floors'

export const useLocationsStore = defineStore(
  'locations',
  function() {
    const locations = ref<LocationGet[]>([]);
    const currentLocation = ref<LocationGet | undefined>(undefined);
    const currentLocationCameras = ref<ApiCameraWithIncludes[]| undefined>(undefined);
    const loadingLocations = ref(false);
    const cameras = useCamerasStore();
    const floorStore = useFloorsStore();

    async function getAllLocations() {
      loadingLocations.value = true;
      try {
        const data = await api.getLocations();
        if(data) {
          locations.value = data.results!;
        }
      } catch (error) {
        console.error(error)
      }
      loadingLocations.value = false;
    }

    function setCurrentLocationCameras() {
      if(cameras.cameras){
        currentLocationCameras.value = cameras.cameras.filter((camera)=> camera.locationId === currentLocation.value?.id);
      }
    }

    async function setCurrentLocationAndFloorplan(location: LocationGet, floor?: FloorResponse) {
      currentLocation.value = location
      setCurrentLocationCameras()
      await floorStore.getAllFloorsByLocationId()

      let floorIndex = 0
      if (floor) {
        const floorIndexInStore = floorStore.floors.findIndex((floorItem: FloorResponse) => floorItem.id === floor.id)

        floorIndex = floorIndexInStore > -1 ? floorIndexInStore : 0
      }
      await floorStore.setCurrentFloor(floorStore.floors[floorIndex])
    }
    

    return {
      locations,
      currentLocation,
      loadingLocations,
      getAllLocations,
      currentLocationCameras,
      setCurrentLocationCameras,
      setCurrentLocationAndFloorplan
    }
  }
)
