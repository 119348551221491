<template>
  <div>
    <eewc-confirmation-dialog
      :show-dialog="messagingStore.confirmationDialog.showDialog"
      :icon-color="messagingStore.confirmationDialog.iconColor"
      :icon="messagingStore.confirmationDialog.icon"
      @closeDialog="messagingStore.resetConfirmationDialog()"
    >
      <template #title>
        {{ $t(messagingStore.confirmationDialog.titleSlot) }}
      </template>
      <template #body>
        {{ $t(messagingStore.confirmationDialog.bodySlot) }}
      </template>
      <template #buttonright>
        <eewc-button-common
          color="accent"
          @click="messagingStore.resetConfirmationDialog()"
        >
          {{ $t("Cancel") }}
        </eewc-button-common>
      </template>
      <template #buttonleft>
        <eewc-button-common
          color="negative"
          type="depressed"
          :loading="loading"
          @click="confirmButtonCallback(messagingStore.confirmationDialog.id)"
        >
          {{ $t(messagingStore.confirmationDialog.buttonMessage) }}
        </eewc-button-common>
      </template>
    </eewc-confirmation-dialog>
  </div>
</template>

<script>

import {useMessagingStore} from '@/stores';
import { ref } from "vue";

export default {
  name: "App",
  setup() {
    const messagingStore = useMessagingStore();
    const loading = ref(false)

    async function confirmButtonCallback(itemId) {
      this.loading = true;
      await messagingStore.confirmationDialog.confirmButtonCallback(itemId); // make the delete http req
      this.loading = false;

      messagingStore.addNotification({
        iconColor: "warning",
        icon: "$icon_attention",
        title: this.$t("Deleted"),
        body: `${messagingStore.confirmationDialog.name} ${this.$t("has been successfully deleted")}`,
      });

      messagingStore.resetConfirmationDialog(); // remove the confirmation dialog from the screen
    }

    return { loading, messagingStore, confirmButtonCallback }
  },
}
</script>

<style></style>
