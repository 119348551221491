import { ApiCameraWithIncludes, FloorResponse } from '@eencloud/eewc-components/src/service/api-types'

/**
 * Description for Floorplan formulas
 * 
 * Each point in the calculation has properties x and y.
 * Longitudes (like X) are vertical lines that measure east or west of the meridian in Greenwich, England.
 * Latitudes (like Y) are horizontal lines that measure distance north or south of the equator.
 * To not get lost in the calculations, I used specific variable names. 
 * Capital letters after variable name have meanings:
 *
 *  Letters:                                     Ranges:
 *  L = point contains Longitude and Latitude.   x: (-180)-(180)                y: (-85)-85 
 *  W = point contains World pixels.             x: 0-256                       y: 0-256
 *  P = point contains Percentage.               x: 0-100 (if inside floor)     y: 0-100 (if inside floor)
 *  
 *  R = point has been rotated 
 *  D = difference between points
 *  
 */


// Types
export type Point = {
  x: number
  y: number,
}

// Helpers
export const degreeToRad = function (degree: number) {
  return degree * Math.PI / 180
}

export const radToDegree = function (rad: number) {
  return rad * (180 / Math.PI)
}

export const getHiddenMapProjection = () => window.hiddenMap.map.getProjection();

const getFloorP1P2 = (floor: FloorResponse) => {
  return {
    p1: {
      x: floor.topLeftCoordinates.longitude,
      y: floor.topLeftCoordinates.latitude
    },

    p2: {
      x: floor.bottomRightCoordinates.longitude,
      y: floor.bottomRightCoordinates.latitude
    }
  }
}


// Points manipulation

const getPointsDifference = (points: { p1: Point, p2: Point }) => {
  return {
    x: points.p2.x - points.p1.x,
    y: points.p2.y - points.p1.y
  }
}

const getRotatedPointRelativelyToPoint = (p1: Point, p2: Point, rotation: number) => {
  const difference = getPointsDifference({
    p1,
    p2
  })

  return {
    x: ((difference.x) * Math.cos(rotation) - (difference.y) * Math.sin(rotation)) + p1.x,
    y: ((difference.x) * Math.sin(rotation) + (difference.y) * Math.cos(rotation)) + p1.y
  }
}

const getPointsCenter = (points: { p1: Point, p2: Point }) => {
  return {
    x: (points.p1.x + points.p2.x) / 2,
    y: (points.p1.y + points.p2.y) / 2
  }
}


// Converting points
export const convertLatLonPointToWorldPoint = (point: Point) => {
  const { x, y } = getHiddenMapProjection().fromLatLngToPoint({lat: point.y, lng: point.x});
  return {
    x: x,
    y: y,
  }
}

export const convertLatLonPointsToWorldPoints = (points: { p1: Point, p2: Point }) => {
  return {
    p1: convertLatLonPointToWorldPoint(points.p1),
    p2: convertLatLonPointToWorldPoint(points.p2)
  }
}

export const convertWorldPointToLatLonPoint = (point: Point) => {
  const map_point = new window.googleInstance.maps.Point(point.x, point.y);
  const { lat, lng } = getHiddenMapProjection().fromPointToLatLng(map_point).toJSON();
  return {
    x: lng,
    y: lat,
  }
}

// Get rotation
export const getFloorRotation = (floor: FloorResponse, image: HTMLImageElement) => {
  const floorL = getFloorP1P2(floor);
  const floorW = convertLatLonPointsToWorldPoints(floorL)

  const angle_of_aspect_ratio = Math.atan(image.naturalHeight / image.naturalWidth)
  const angle_of_line_through_points = Math.atan2( 
    floorW.p2.y - floorW.p1.y,  
    floorW.p2.x - floorW.p1.x 
  )

  return angle_of_line_through_points - angle_of_aspect_ratio
}

export const getCameraRotationInFloorplan = (azimuth: number, floor: FloorResponse, image: HTMLImageElement) => {
  const floorRotation = radToDegree(getFloorRotation(floor, image)) || 0
  return (azimuth || 0) - floorRotation;
}

export const getCameraRotationInMap = (azimuth: number, floor: FloorResponse, image: HTMLImageElement) => {
  const floorRotation = radToDegree(getFloorRotation(floor, image)) || 0
  return (azimuth || 0) + floorRotation;
}

// Get positions
export const getCamerasPositionsInFloor = (
  floor: FloorResponse, 
  cameras: ApiCameraWithIncludes[], 
  image: HTMLImageElement
) => {
  const floorRotation = getFloorRotation(floor, image)
  const floorL = getFloorP1P2(floor)
  const floorW = convertLatLonPointsToWorldPoints(floorL)
  const floorCenterW = getPointsCenter(floorW)

  const floorWR = {
    p1: getRotatedPointRelativelyToPoint(floorCenterW, floorW.p1, -floorRotation),
    p2: getRotatedPointRelativelyToPoint(floorCenterW, floorW.p2, -floorRotation)
  }

  return cameras.map<any>((camera) => {
    if (!camera.devicePosition?.longitude || !camera.devicePosition?.latitude) {
      return camera
    }

    const cameraL = {
      x: camera.devicePosition.longitude,
      y: camera.devicePosition.latitude
    }
    const cameraW = convertLatLonPointToWorldPoint(cameraL)
    const cameraWR = getRotatedPointRelativelyToPoint(floorCenterW, cameraW, -floorRotation)

    const floorP1ToCameraWRD = getPointsDifference({
      p1: floorWR.p1,
      p2: cameraWR
    })
    const floorP1ToP2WRD = getPointsDifference(floorWR)

    const floorP1ToP2PD = {
      x: Math.abs(floorP1ToCameraWRD.x) / Math.abs(floorP1ToP2WRD.x) * 100,
      y: Math.abs(floorP1ToCameraWRD.y) / Math.abs(floorP1ToP2WRD.y) * 100
    }
    
    return {
      ...camera,
      floorP1ToP2PD: floorP1ToP2PD,
    }
  })
}

export const isCameraInsideFloorplan = (camera:
  ApiCameraWithIncludes &
  { floorP1ToP2PD: { x: number, y: number } }
) => {
  if (!camera.floorP1ToP2PD || !camera.floorP1ToP2PD.x || !camera.floorP1ToP2PD.y) {
    return false
  }
  
  return (
    (0 <= camera.floorP1ToP2PD.x && camera.floorP1ToP2PD.x <= 100) &&
    (0 <= camera.floorP1ToP2PD.y && camera.floorP1ToP2PD.y <= 100)
  )
}

export const getCursorCoordinates = (
  floor: FloorResponse,
  floorP1ToP2PD: { x: number, y: number },
  image: HTMLImageElement
) => {
  const floorRotation = getFloorRotation(floor, image)
  const floorL = getFloorP1P2(floor)
  const floorW = convertLatLonPointsToWorldPoints(floorL)
  const floorCenterW = getPointsCenter(floorW)

  const floorWR = {
    p1: getRotatedPointRelativelyToPoint(floorCenterW, floorW.p1, -floorRotation),
    p2: getRotatedPointRelativelyToPoint(floorCenterW, floorW.p2, -floorRotation)
  }

  const floorP1ToP2RWD = getPointsDifference(floorWR)

  const cursorRW = {
    x: (floorP1ToP2RWD.x * floorP1ToP2PD.x) + floorWR.p1.x,
    y: (floorP1ToP2RWD.y * floorP1ToP2PD.y) + floorWR.p1.y,
  }

  const cursorW = getRotatedPointRelativelyToPoint(floorCenterW, cursorRW, floorRotation)
  const cursorC = convertWorldPointToLatLonPoint(cursorW);
  
  return cursorC
}
