import { defineStore } from 'pinia'
import { ref, watch, nextTick } from 'vue'
import {
  ApiCameraWithIncludes, ApiDevicePositionCamera, FloorResponse,
} from '@eencloud/eewc-components/src/service/api-types'
import { useLocationsStore } from '@/stores/locations'
import { useFloorplansStore } from '@/stores/floorplans'
import {
  getCamerasPositionsInFloor,
  getFloorRotation,
  isCameraInsideFloorplan,
} from '@/service/FloorplanFormulas'
import api from '@eencloud/eewc-components/src/service/api'
import router from '@/service/router'

export const useFloorsStore = defineStore('floors', () => {
  const floors = ref<FloorResponse[] | []>([])
  const currentFloor = ref<FloorResponse | undefined>(undefined)
  const currentFloorCameras = ref<ApiCameraWithIncludes[] | undefined>(undefined)
  const locations = useLocationsStore()
  const floorplans = useFloorplansStore()
  const loading = ref<boolean>(false)
  const isAddingCamerasToFloorplanActive = ref<boolean>(false)
  const currentEditingCamera = ref<ApiCameraWithIncludes | null>();
  const isEditCamerasFloorEnabled = ref<boolean>(false);
  const isEditSettingsActive = ref<boolean>(false);
  
  watch(() => currentFloor.value?.id, async () => {
    currentFloorCameras.value = undefined

    try {
      await floorplans.fetchFloorplanImage()
    } catch (error) {
      return
    }
    setCurrentFloorCameras()
  })

  async function getAllFloorsByLocationId () {
    if (locations.currentLocation?.id) {
      loading.value = true
      floors.value = []
      currentFloor.value = undefined
      await nextTick()

      try {
        const data = await api.getFloors(locations.currentLocation.id)
        if (data) {
          const floorsResponse = data.results!
          const sortedFloors = sortByFloors(floorsResponse)

          floors.value = sortedFloors
        }
      } catch (error) {
        console.error(error)
      }
      loading.value = false
    }
  }

  function getFloorById ( searchedFloorId: string ) {
    return floors.value.find((floor: FloorResponse) => floor.id === searchedFloorId)
  }
  function setCurrentFloorCameras() {
    const currentLocationCameras = locations.currentLocationCameras;
    if (!currentFloor.value || !currentLocationCameras) {
      return
    }

    const camerasWithPositionsRelatedToCurrentFloor = getCamerasPositionsInFloor(
      currentFloor.value,
      currentLocationCameras,
      floorplans.currentFloorImage
    )

    currentFloorCameras.value = camerasWithPositionsRelatedToCurrentFloor.filter((camera) => {
      const cameraFloor = camera.devicePosition?.floor
      const isTheSameFloorLevel = cameraFloor === currentFloor.value?.floorLevel;

      return isCameraInsideFloorplan(camera) && isTheSameFloorLevel;
    })
  }

  async function setCurrentFloor (floor: FloorResponse | undefined) {
    const locationId = locations.currentLocation?.id
    currentFloor.value = floor
    try {
      if (floor) {
        await router.push({ 'path': `/location/${locationId}/floor_plan/${floor.id}` })
      } else {
        await router.push({ 'path': `/no-floorplan` })
      }
    } catch (error) {
      // empty
    }
  }

  function sortByFloors(floors: FloorResponse[]) {
    return floors.sort(function (a, b) {
      return a.floorLevel - b.floorLevel;
    });
  }

  return {
    floors,
    currentFloor,
    currentFloorCameras,
    getAllFloorsByLocationId,
    getFloorById,
    setCurrentFloorCameras,
    loading,
    isAddingCamerasToFloorplanActive,
    currentEditingCamera,
    isEditCamerasFloorEnabled,
    isEditSettingsActive,
    setCurrentFloor,
  }
})
