import { defineStore } from 'pinia'
import { ref, nextTick } from 'vue'
import {
  GetFloorId,
  GetLocationId,
  PaginatedFloorplansResponse
} from '@eencloud/eewc-components/src/service/api-types'
import api from '@eencloud/eewc-components/src/service/api'
import { useFloorsStore } from '@/stores/floors'
import { useLocationsStore } from '@/stores/locations'

export const useFloorplansStore = defineStore('floorplans', () => {
  const floor = useFloorsStore()
  const location = useLocationsStore()

  const currentFloorFloorplans = ref<PaginatedFloorplansResponse | undefined>(undefined)
  const currentFloorplanSrc = ref<string | undefined>('')
  const currentFloorImage = ref<HTMLImageElement>(new Image())
  const loadingFloorplans = ref(false);
  const cachedFloorplansImages = new Map();

  const fetchFloorplanImage = async () => {
    const locationId = location.currentLocation?.id
    const floorId = floor.currentFloor?.id

    loadingFloorplans.value = true;

    // reset image
    currentFloorImage.value = new Image()
    currentFloorplanSrc.value = undefined
    await nextTick();

    if (!locationId || !floorId) {
      loadingFloorplans.value = false;
      throw new Error('Empty floorplans array')
    }

    // fetch floorplans that contains information about file type
    currentFloorFloorplans.value = await api.getFloorplans(locationId, floorId)

    if (!currentFloorFloorplans.value?.results?.length) {
      loadingFloorplans.value = false;
      throw new Error('Empty floorplans array')
    }

    const type = currentFloorFloorplans.value.results[0].type

    // prepare image src
    currentFloorplanSrc.value = await fetchOrGetCashedFloorplan(locationId, floorId, type)
    await loadImage();

    loadingFloorplans.value = false
  }

  const loadImage = function () {
    return new Promise(function (resolve, reject) {
      currentFloorImage.value.onload = function () {
        resolve(null)
      };
      currentFloorImage.value.onerror = function () {
        reject();
      }
      if(currentFloorplanSrc.value){
        currentFloorImage.value.src = currentFloorplanSrc.value
      }
    })
  }

  const fetchOrGetCashedFloorplan = async function (locationId: GetLocationId, floorId: GetFloorId, type: string) {
    const key = `${locationId},${floorId}`
    if (cachedFloorplansImages.has(key)) {
      return cachedFloorplansImages.get(key)
    } else {
      const blob = await api.getFloorplan(locationId, floorId, type)
      const urlObject = URL.createObjectURL(blob)
      return cachedFloorplansImages.set(key, urlObject).get(key)
    }
  }

  const deleteCashedFloorplan = function (locationId: GetLocationId, floorId: GetFloorId) {
    const key = `${locationId},${floorId}`
    const blob = cachedFloorplansImages.get(key)
    URL.revokeObjectURL(blob)
    cachedFloorplansImages.delete(key)
  }

  return {
    fetchFloorplanImage,
    currentFloorImage,
    currentFloorplanSrc,
    currentFloorFloorplans,
    loadingFloorplans,
    deleteCashedFloorplan
  }
})
