import api from "@eencloud/eewc-components/src/service/api";
import { ApiPtzMove } from "@eencloud/eewc-components/src/service/api-types";
import { defineStore } from "pinia";


export const usePtzStore = defineStore(
  'ptz',
  function() {
    async function getPTZLocation(cameraId: string) {
      try {
        return await api.getPTZPosition(cameraId);
      } catch (error) {
        console.error(error);
      }
    }

    async function movePTZ(payload: { request: ApiPtzMove, cameraId: string }) {
      try {
        await api.movePTZ({ cameraId: payload.cameraId, movementData: payload.request });
      } catch (error) {
        console.error(error);
      }
    }

    return {
      getPTZLocation,
      movePTZ,
    }
  }
)