<template>
  <div class="d-flex flex-column align-center pb-3">
    <div class="select-floor-component">
      <h2 class="page-title-1 mt-10 mb-5">{{ t('Select floor') }}</h2>
      <eewc-select-box
        :placeholder="t('Select floor')"
        selection-slot
        item-slot
        :value="currentFloor"
        :items="availableFloors"
        @input="selectFloor"
      >
        <template #selection="{item}">
          {{ item.text }}
        </template>
        <template #item="{item}">
          {{ item.text }}
        </template>
      </eewc-select-box>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { t } from "@eencloud/eewc-components/src/service/locale";
import { prepareAvailableFloorsLevels } from '@/service/helpers';
import { useLocationsStore, useNewFloorStore, useFloorsStore } from '@/stores';

const floors = useFloorsStore();
const locations = useLocationsStore();
const newFloorStore = useNewFloorStore();

const props = defineProps<{
  stepIndex: number
}>()

const currentFloor = computed(() => {
  return {
    text: newFloorStore?.selectedFloor,
    value: newFloorStore?.selectedFloor
  }
})

const availableFloors = computed(()=>{
  const usedFloors = newFloorStore.locationFloors || [];
  return prepareAvailableFloorsLevels(usedFloors);
})

const selectFloor = function(floor: {text: string, value: number}) {
  newFloorStore.$patch({
    selectedFloor: floor.value,
    newFloorName:`${t('Floor')} ${floor.value}`,
    defaultFloorName: `${t('Floor')} ${floor.value}`
  })
  newFloorStore.setIsCompletedStep(props.stepIndex, true)
}

</script>

<style scoped lang="scss">
@import "../../../../styles/public/main.scss";

.select-floor-component {
  max-width: 430px;
  width: 100%;
}

.page-title-1 {
  @include subtitle-1;
}
</style>
