import Vue from 'vue'
import Vuetify from 'vuetify'
// @ts-ignore
import { icons } from '@eencloud/eewc-components/src/assets/icons/icons'
// @ts-ignore
import Theme from "@eencloud/eewc-components/src/service/themes";

Vue.use(Vuetify)

const theme = new Theme('EEN').colors()

export default new Vuetify({
  icons,
  theme
})
