<template>
  <v-app
    ref="fullScreenRef"
  >
    <v-main
      class="px-3"
      :class="{'sidebar-open': floorsStore.isAddingCamerasToFloorplanActive}"
    >
      <eewc-notification-dialog
        v-if="messagingStore.notification"
        :icon-color="messagingStore.notification.iconColor"
        :icon="messagingStore.notification.icon"
        :title="messagingStore.notification.title"
        :body="messagingStore.notification.body"
        @closeNotificationDialog="messagingStore.removeNotification()"
      />
      <div class="router-wrapper">
        <router-view
          :key="$route.path"
        />
      </div>
    </v-main>
    <div
      v-if="appStore.loading"
      class="loader-wrapper"
    >
      <eewc-loading-spinner
        :is-loading="appStore.loading"
        :size="60"
      />
    </div>
    <confirmation-dialog-wrapper />
  </v-app>
</template>

<script setup lang="ts">
// service
import { onMounted, ref } from 'vue'
import {
  useAppStore,
  useFloorsStore,
  useMessagingStore,
} from '@/stores'
import ConfirmationDialogWrapper from '@/components/ConfirmationDialogWrapper.vue'

const floorsStore = useFloorsStore()
const appStore = useAppStore();
const messagingStore = useMessagingStore();

const fullScreenRef = ref();

onMounted(() => {
  appStore.$patch({'fullScreenElRef': fullScreenRef.value.$el})
});

</script>

<style lang="scss">
@import "styles/public/main.scss";

html, body {
  overflow: hidden;
}

.v-application {
  &:fullscreen {
    .hide-on-fullscreen {
      display: none!important;
    }
  }
}

.v-main {
  background: $primaryWhite;
}

.v-main__wrap {
  max-height: 100vh;
}
.sidebar-open {
  margin-right: 410px;
}

.router-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.loader-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 47%);
}
</style>
