<template>
  <div class="d-flex flex-column align-center pb-3">
    <div class="rename-component">
      <h2 class="page-title-1 mt-10 mb-5">{{ t('Rename floor') }}</h2>
      <div class="upload-file__image-wrapper">
        <img
          :src="uploadedFileUrl"
        >
      </div>
      <div class="d-flex align-center">
        <span class="mr-3">{{ floorNameLabel }}:</span>
        <eewc-text-field
          v-model="floorName"
          class="flex-grow-1"
          :label="floorNameLabel"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from '@eencloud/eewc-components/src/service/locale'
import { useNewFloorStore } from '@/stores'
import {computed, defineProps, onMounted} from 'vue'

const props = defineProps<{
  stepIndex: number
}>();

const newFloorStore = useNewFloorStore()
const uploadedFileUrl = computed(()=>newFloorStore.uploadedFileUrl)
const floorName = computed({
  get: () => newFloorStore.newFloorName,
  set: (value) => newFloorStore.$patch({newFloorName: value})
})
const floorNameLabel = computed(()=> `${t('Floor')} ${newFloorStore.selectedFloor}`)

onMounted(() => {
  newFloorStore.setIsCompletedStep(props.stepIndex, true);
})
</script>

<style scoped lang="scss">
@import "../../../../styles/public/main.scss";

.page-title-1 {
  @include subtitle-1;
}
.rename-component {
  max-width: 430px;
  width: 100%;
}
.upload-file {
  &__image-wrapper {
    position: relative;
    padding: 12px;
    border: 1px solid $elements;
    border-radius: 4px;
    margin-bottom: 30px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}
</style>
