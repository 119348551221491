<template>
  <div>
    <eewc-loading-spinner :is-loading="loading && !preventLoading" />
    <canvas
      :id="`preview-image-${cameraId}`"
      ref="previewImage"
      class="preview-canvas"
    />
  </div>
</template>

<script>
import { authKey } from '@eencloud/eewc-components/src/service/auth.js';
import ApiList from "@eencloud/eewc-components/src/service/api";
import { reactive, toRefs, ref, onMounted, onUnmounted, watch } from "vue";
import { useFeedsStore } from "@/stores";
import LivePlayer from "@een/live-video-web-sdk";
import lodash from "lodash";

export default {
  name: "PreviewImage",
  props: {
    cameraId: {
      type: String,
      required: true,
    },
    maxHeight: {
      type: Number,
      default: undefined,
    },
    fullQualityVideo: {
      type: Boolean,
      default: false
    },
    preventLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const previewImage = ref(null);
    const feedsStore = useFeedsStore();
    const state = reactive({
      loading: false,
      reader: null,
      signal: null,
      controller: null,
      player: null,
    });

    onMounted(() => {
      state.controller = new AbortController();
      state.signal = state.controller.signal;
      state.loading = true;
      loadPreviewStream()
    });

    function resizeEvent() {
      previewImage.value.width = previewImage.value.offsetWidth;
      previewImage.value.height = previewImage.value.offsetHeight
    }

    watch(() => props.fullQualityVideo, fullQuality => {
      const resizeObserver = new ResizeObserver(lodash.debounce(resizeEvent, 200));
      if(fullQuality) { // stop the preview stream and remove event listener
        state.controller?.abort();
        state.controller = new AbortController();
        state.signal = state.controller.signal;
        resizeObserver.observe(previewImage.value);
      } else { // stop the live video player
        state.player.stopStream();
        resizeObserver.unobserve(previewImage.value);
      }
      loadPreviewStream();
    })

    function loadPreviewStream() {
      if(props.fullQualityVideo) {
        startFullQualityStream();
      } else {
        startJpegStream();
      }
    }

    async function startJpegStream() {
      const canvas = previewImage.value;
      const feed =  await feedsStore.getPreviewFeed(props.cameraId);
      if(canvas) {
        state.loading = true;
        if(window.jpegStreamParser && feed) {
          const streamUrl = feed.multipartUrl;
          stream(streamUrl)
          window.jpegStreamParser.addEventListener("message", async ({data}) => {
            try {
              const canvas = previewImage.value;
              if (canvas && data.cameraId === props.cameraId) {
                const ctx = canvas.getContext('2d');
                const bitmap = await createImageBitmap(new Blob([ data.value.data ], { type: 'image/jpeg' }))
                canvas.height = bitmap.height;
                canvas.width = bitmap.width;
                ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
                bitmap.close()
                context.emit('timestamp', createTimeStamp(data.value.timestamp));
              }
            } catch(error) {
              console.log(`A frame for camera ${props.cameraId} failed to decode, due to the following error: ${error}, Timestamp: ${data.value.timestamp}`)
            }
          }, 
            { 
              capture: false,
              once: false,
              passive: false,
              signal: state.signal
            }
          );
        } else {
          state.loading = false;
        }
      }
    }

    async function startFullQualityStream() {
      state.loading = true;
      const feed =  await feedsStore.getMainFeed(props.cameraId);
      previewImage.value.width = previewImage.value.offsetWidth
      previewImage.value.height = previewImage.value.offsetHeight
      
      state.player = new LivePlayer({
        canvasElement: previewImage.value,
        onFrame: (time) => onFrame(time),
        feedUrl: feed.multipartUrl,
        accessToken: ApiList.token
      });
      state.player.start();

      function onFrame(time) {
        state.loading = false;
        createTimeStamp(time);
        context.emit('timestamp', createTimeStamp(time));
      }
    }

    function stream(streamUrl) {
      fetch(streamUrl, {
        signal: state.signal,
        headers: {
          Authorization: 'bearer ' + ApiList.token,
        },
      })
        .then((res) => {
          if (res.body) {
            state.reader = res.body.getReader();
            const cameraId = props.cameraId
            state.reader.read().then(function processResult(result) {
              if (result.done) {
                console.log("Video Stream is done.");
                state.loading = false
                return Promise.resolve();
              }
              const transfer = [result.value.buffer];
              window.jpegStreamParser?.postMessage({ cameraId, value: result.value }, transfer);
              state.loading = false
              return state.reader.read().then(processResult);
            });
          }
        })
        .catch((err) => {
          state.loading = false
          console.error("Video Stream Request error", err);
        })
    }

    onUnmounted(() => {
      previewImage.value = ref(null);
      state.reader?.cancel();
      state.player?.stopStream();
      window.jpegStreamParser?.postMessage({ cameraId: props.cameraId });
      state.controller.abort();
    });

    function createTimeStamp(EENTimestamp) {
      const str = EENTimestamp.toString();
      const hour = str.substring(8,10);
      const min = str.substring(10,12);
      const secDotMilli = str.substring(12,18);
      return [hour,min,secDotMilli].join(':');
    }

    return { ...toRefs(state), previewImage };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/public/main.scss";
.preview-canvas {
  width: 100%;
  height: 100%;
  background: $primary;
  border-radius: 4px;
}
</style>
