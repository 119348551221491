<template>
  <div class="new-floor-plan__page">
    <header class="title--container d-flex flex-column mb-3">
      <!--  ROW 1  -->
      <div class="d-flex justify-space-between align-center my-3">
        <div
          class="title__name"
        >
          <span class="text--disabled">{{ t('Floor plans') }}</span>

          <v-icon
            size="16"
            class="mx-2"
          >
            $icon_arrow_right
          </v-icon>

          {{ t('Add new floor plan') }}
        </div>
        <div>
          <eewc-button-common
            type="outlined"
            color="primary"
            @click="cancelNewFloor"
          >
            {{ t('Cancel') }}
          </eewc-button-common>
          <eewc-button-common
            v-if="nextStep"
            class="ml-3"
            color="accent"
            :disabled="!activeStep.isCompleted"
            @click="activeStep = nextStep"
          >
            {{ t('Next') }}
          </eewc-button-common>
          <eewc-button-common
            v-else
            class="ml-3"
            color="accent"
            :disabled="!activeStep.isCompleted"
            @click="saveFloor"
          >
            {{ t('Confirm') }}
          </eewc-button-common>
        </div>
      </div>
    </header>
    <eewc-progress-bar-steps
      v-model="activeStep"
      :steps="newFloorStore.steps"
      :max-width-container="600"
    />

    <v-tabs-items
      v-model="activeStep"
      class="new-floor-plan__tab-items"
    >
      <v-tab-item
        v-for="(item, index) in newFloorStore.steps"
        :key="item.id"
        :value="item"
      >
        <component
          :key="activeStep.id"
          :is="item.component"
          ref="activeStepComponent"
          :step-index="index"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script setup lang="ts">
import { useNewFloorStore, useLocationsStore, useAppStore, useFloorsStore } from '@/stores'
import { t } from '@eencloud/eewc-components/src/service/locale';
import { computed, ref, onMounted } from 'vue';
import api from '@eencloud/eewc-components/src/service/api';
import router from "@/service/router";

const newFloorStore = useNewFloorStore();
const floorStore = useFloorsStore();
const locationStore = useLocationsStore();
const appStore = useAppStore();
const activeStep = ref(newFloorStore.steps[0]);
const activeStepComponent = ref();

const nextStep = computed(() => {
  const activeStepIndex = newFloorStore.steps.findIndex((step) => step.id === activeStep.value.id);
  return newFloorStore.steps[activeStepIndex + 1];
})
const saveFloor = async () => {
  const setLocationComponent = activeStepComponent.value[4];
  setLocationComponent.calculateFloorplanCoords();
  appStore.loading = true;
  if (!newFloorStore.selectedLocation?.id) {
    return;
  }
  const locationId = newFloorStore.selectedLocation?.id
  const image = new Image();
  let newFloor;
  await (new Promise((resolve) => {
      image.onload = () => {
        resolve(true)
      }
      image.src = newFloorStore.uploadedFileUrl || ''
    })
  )

  try {
    newFloor = await (api.addFloor(locationId, {
        name: newFloorStore.newFloorName || newFloorStore.defaultFloorName,
        floorLevel: newFloorStore.selectedFloor,
        topLeftCoordinates: newFloorStore.topLeftCoordinates,
        bottomRightCoordinates: newFloorStore.bottomRightCoordinates
      })
    )
  } catch (err) {
    appStore.loading = false
  }

  if (!newFloor || !newFloorStore.uploadedFileFormData) {
    return;
  }
  
  try {
    await api.addFloorplan(locationId, newFloor.id, newFloorStore.uploadedFileFormData)
    await locationStore.setCurrentLocationAndFloorplan(newFloorStore.selectedLocation, newFloor)
    floorStore.$patch({
      isEditCamerasFloorEnabled: true,
      isAddingCamerasToFloorplanActive: true
    })
    appStore.loading = false;
  } catch(error) {
    await api.deleteFloor(locationId, newFloor.id);
    appStore.loading = false;
  }
}

onMounted(() => {
  clearNewFloorStepper();
})

const clearNewFloorStepper = function () {
  newFloorStore.steps.map(step => step.isCompleted = false)
  newFloorStore.selectedLocation = undefined
  newFloorStore.selectedFloor = undefined
  newFloorStore.uploadedFileUrl = ''
  newFloorStore.uploadedFile = undefined
}
const cancelNewFloor = function () {
  router.back()
}
</script>

<style lang="scss" scoped>
@import "../../../styles/public/main.scss";

.new-floor-plan {
  &__page {
    display: flex;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
  }

  &__tab-items {
    overflow: auto;
    height: 100%;
  }
}

</style>
