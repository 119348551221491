<template>
  <div>
    <div class="ptz-controls">
      <div class="ptz-controls__wheel">
        <span class="ptz-controls__wheel--pan">
          <v-icon
            class="wheel__icon"
            size="28"
            @click="move('small', ['left'])"
          >$icon_arrow_left</v-icon>
          <v-icon
            class="wheel__icon"
            size="28"
            @click="move('small', ['right'])"
          >$icon_arrow_right</v-icon>
        </span>
        <span class="ptz-controls__wheel--tilt">
          <v-icon
            class="wheel__icon"
            size="28"
            @click="move('small', ['up'])"
          >$icon_arrow_up</v-icon>
          <v-icon
            class="wheel__icon"
            size="28"
            @click="move('small', ['down'])"
          >$icon_arrow_down</v-icon>
        </span>
      </div>
      <div class="ptz-controls__zoom">
        <div
          class="ptz-controls__zoom--button"
          @click="incrementalZoom('out')"
        >
          <v-icon>$icon_minus</v-icon>
        </div>
        <div class="ptz-controls__zoom--slider">
          <eewc-slider-simple
            v-model="zoomLevel"
            :min="0"
            :max="1"
            :step="0.01"
            :disabled="disableZoomSlider"
            @input="zoomToPosition"
          />
        </div>
        <div
          class="ptz-controls__zoom--button"
          @click="incrementalZoom('in')"
        >
          <v-icon>$icon_plus</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount, watch } from "vue";
import { usePtzStore } from "@/stores";

export default {
  props: {
    cameraId: {
      type: String,
      required: true,
    },
    videoElement: { type: HTMLVideoElement, default: undefined },
  },
  setup(props) {
    const ptzStore = usePtzStore();
    const state = reactive({
      zoomLevel: 0,
      disableZoomSlider: true,
    });
    useMouseControls(props, incrementalZoom, ptzStore);

    onMounted(async () => {
      await getPosition();
      state.disableZoomSlider = false;
    });

    watch(
      () => ptzStore.ptzZoomStatuses,
      async () => {
        state.zoomLevel = ptzStore.ptzZoomStatuses.find((status) => status.cameraId === props.cameraId)?.z;
      }
    );

    function incrementalZoom(direction, step = 0.1) {
      // for the buttons next to the zoom slider
      let newZoomValue = direction === "in" ? state.zoomLevel + step : state.zoomLevel - step;
      if (newZoomValue < 0) {
        newZoomValue = 0;
      } else if (newZoomValue > 1) {
        newZoomValue = 1;
      }
      if (state.zoomLevel !== newZoomValue) {
        state.zoomLevel = newZoomValue;
        zoomToPosition();
      }
    }

    async function zoomToPosition() {
      await ptzStore.movePTZ({
        cameraId: props.cameraId,
        request: { moveType: "position", z: state.zoomLevel },
      });
    }

    async function getPosition() {
      const pos = await ptzStore.getPTZLocation(props.cameraId);
      if (pos) {
        state.zoomLevel = pos.z;
      }
    }

    async function move(stepSize, direction) {
      // not recommended for zooming because we loose track of the current zoom level
      const moveType = "direction";
      await ptzStore.movePTZ({ cameraId: props.cameraId, request: { moveType, direction, stepSize } });
    }
    return { ...toRefs(state), zoomToPosition, move, incrementalZoom };
  },
};
function useMouseControls(props, incrementalZoom, ptzStore) {
  const video = props.videoElement;
  const canvasElement = document.createElement("canvas");
  const canvas = video.insertAdjacentElement("afterend", canvasElement);
  canvas.className = "ptz-control-overlay";
  canvas.height = video.clientHeight;
  window.addEventListener("resize", onResize);
  canvas.width = video.clientWidth;
  canvas.style = "z-index: 1;";
  const ctx = canvas.getContext("2d");
  let mousedown = false;
  let x1, y1, x2, y2;

  canvas.onmousedown = (e) => {
    mousedown = true;
    x1 = e.offsetX;
    y1 = e.offsetY;
  };
  canvas.onmouseup = async (e) => {
    mousedown = false;
    const x = e.offsetX;
    const y = e.offsetY;
    ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
    if (x == x1 && y == y1) {
      // user clicks without drawing rectangle
      renderRipple(x, y);
      const relativeX = x / canvas.clientWidth;
      const relativeY = y / canvas.clientHeight;
      await ptzStore.movePTZ({
        cameraId: props.cameraId,
        request: { moveType: "centerOn", relativeX, relativeY },
      });
    } else {
      // user draws a rectangle
      const relativeX = (x1 + (x - x1) / 2) / canvas.clientWidth;
      const relativeY = (y1 + (y - y1) / 2) / canvas.clientHeight;
      const relativeRectangleSize = ((x1 - x) * (y1 - y)) / (canvas.clientWidth * canvas.clientHeight);
      // the smaller the rectangle the more we zoom
      const zoomStep = relativeRectangleSize > 0.2 ? (relativeRectangleSize > 0.5 ? 0.1 : 0.3) : 0.5;
      await ptzStore.movePTZ({
        cameraId: props.cameraId,
        request: { moveType: "centerOn", relativeX, relativeY },
      });
      incrementalZoom("in", zoomStep);
    }
  };
  canvas.onmousemove = (e) => {
    if (mousedown) {
      x2 = e.offsetX;
      y2 = e.offsetY;
      redrawRect();
    }
  };
  function redrawRect() {
    ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
    ctx.beginPath();
    ctx.lineWidth = "3";
    ctx.strokeStyle = "white";
    ctx.rect(x1, y1, x2 - x1, y2 - y1);
    ctx.stroke();
  }
  function renderRipple(x, y) {
    let start, previousTimeStamp;
    let done = false;
    window.requestAnimationFrame(drawCircle);

    function drawCircle(timestamp) {
      if (start === undefined) {
        start = timestamp;
      }
      const elapsed = timestamp - start;
      if (previousTimeStamp !== timestamp) {
        const count = Math.min(0.1 * elapsed, 30);
        ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
        ctx.beginPath();
        ctx.lineWidth = 8 - (count * 2) / 10;
        ctx.strokeStyle = "white";
        ctx.arc(x, y, count, 0, 8 * Math.PI);
        ctx.stroke();
        if (count === 30) {
          done = true;
          ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
        }
      }
      if (elapsed < 500) {
        previousTimeStamp = timestamp;
        !done && window.requestAnimationFrame(drawCircle);
      }
    }
  }
  function onResize() {
    canvas.height = video.clientHeight;
    canvas.width = video.clientWidth;
  }
  onBeforeUnmount(() => {
    canvasElement.remove();
    removeEventListener("resize", onResize);
  });
}
</script>

<style lang="scss">
.ptz-control-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}
</style>

<style lang="scss" scoped>
@import "../../styles/public/main.scss";

.ptz-controls {
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  bottom: 40px;
  width: 232px;
  z-index: 3;

  &__wheel {
    position: relative;
    border-radius: 140px;
    height: 140px;
    width: 140px;
    margin: auto;
    z-index: 3;
    background: url("../../assets/images/ptz_wheel.svg");
    background-size: cover;

    &--tilt {
      position: absolute;
      top: 10px;
      // 4px to cancel the shadow of the wheel + 10px of spacing
      bottom: 14px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &--pan {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 50%;
      right: 14px;
      left: 10px;
      transform: translateY(-50%);
    }

    .wheel__icon {
      color: $primaryWhite;
      &:hover {
        &,
        * {
          color: $accentLight !important;
        }
      }
      &:active {
        &,
        * {
          color: $accent !important;
        }
      }
      &:focus {
        &::after {
          content: unset !important;
        }
      }
    }
  }

  &__zoom {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-top: 18px;
    z-index: 3;

    &--button {
      background: rgba(33, 42, 52, 0.72);
      backdrop-filter: blur(4px);
      border-radius: 4px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      * {
        color: $primaryWhite;
      }
      &:hover {
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      }
      &:active {
        box-shadow: none !important;
        * {
          color: $secondaryMedium !important;
        }
      }
    }
    &--slider {
      width: 169px;
    }
  }
}
</style>
