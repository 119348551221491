<template>
  <div class="d-flex flex-column pb-3">
    <h2 class="page-title-1 mt-10 mb-5">{{ t('Set location') }}</h2>
    <div class="map-page">
      <div class="map-page__map-element">
        <div
          ref="mapNode"
          class="map-element__map-canvas"
        />
        <div
          id="overview"
          class="map-element__overview-container"
          :class="{ mapToggle: mapToggle }"
        >
          <div class="overview-container__image" />
          <div class="overview-container__text--font">
            {{ mapToggle ? t("Map") : t("Satellite") }}
          </div>
        </div>
        <div
          class="search-wrapper"
        >
          <eewc-searchbar
            v-model="currentSearch"
            :placeholder="t('Search address')"
            :collections="searchCollection || []"
            :no-result="t('No results found')"
            :disable-filtering="true"
            @input="currentSearchChanged"
          />
        </div>
        <div class="map-page--zoomControls">
          <eewc-button-common
            id="map--zoom-in"
            :icon-size="30"
            map
            icon
            append-icon="$icon_zoom_in"
          />
          <eewc-button-common
            id="map--zoom-out"
            :icon-size="30"
            map
            icon
            append-icon="$icon_zoom_out"
          />
        </div>
        <eewc-drr
          :key="newFloorStore.uploadedFileUrl"
          :floorplan-img="newFloorStore.uploadedFileUrl"
          @onChange="calculateFloorplanCoords()"
        ></eewc-drr>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from '@eencloud/eewc-components/src/service/locale'
import { reactive, ref, defineExpose, onMounted, defineProps, onUnmounted, ComputedRef, computed, Ref } from 'vue';
import { useNewFloorStore } from '@/stores'
import { Collection } from '@eencloud/eewc-components/src/components/text-inputs/searchBar/types';
import { debounce } from '@/service/helpers'
import {
  PlacesService,
  createMap,
  AutocompleteService
} from '@/service/googleMaps/googleMaps'
// @ts-ignore
import google from '@types/google.maps';
import { useAppStore } from '@/stores/app'

const newFloorStore = useNewFloorStore();
const mapToggle = ref(false);
const props = defineProps<{
  stepIndex: number
}>();
const currentSearch = ref<string>('');
const appStore = useAppStore();

interface googleData {
  google: google,
  map: google.maps.map,
  placesService: google.maps.places.PlacesService,
  autocompleteService: google.maps.places.AutocompleteService,
}
const mapNode: Ref<HTMLElement | null> = ref(null);
const data: googleData = reactive({
  google: null,
  map: null,
  placesService: null,
  autocompleteService: null
});
type Result = {
  name: string,
  id: string
}

const foundedResults: Ref<Result[] | []> = ref([]);
const searchCollection: ComputedRef<Collection[]> = computed(() => {
  return [
    {
      items: foundedResults.value,
      callback: async function(value) {
        appStore.loading = true;
        try {
          const response = await data.placesService.searchPlaceFromQuery(value.name);
          data.map.setCenter(response);
          data.map.setZoom(17);
        } catch {
          // empty
        }
        appStore.loading = false;
      }
    }
  ]
})

onMounted(() => {
  newFloorStore.setIsCompletedStep(props.stepIndex, true);
  initMap();
});
defineExpose({
  calculateFloorplanCoords
});

function setUserPosition() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(userPosition => {
      data.map.setCenter({
        lat: userPosition.coords.latitude,
        lng: userPosition.coords.longitude
      });
      data.map.setZoom(17);
    });
  }
}

async function trySetAddressCoords() {
  const address = newFloorStore?.selectedLocation?.address;
  const formattedAddress = `${address?.streetAddress} ${address?.streetAddress2} ${address?.postalCode} ${address?.city}`;
  const addressWithoutSpaces = formattedAddress.replace(/\s+/g, ' ').trim();
  appStore.loading = true;

  if (addressWithoutSpaces && data.google.maps) {
    try {
      const response: google.maps.LatLng = await data.placesService.searchPlaceFromQuery(addressWithoutSpaces);
      currentSearch.value = addressWithoutSpaces;
      data.map.setCenter(response);
      data.map.setZoom(17);
    } catch {
      await setUserPosition();
    }
  } else {
    await setUserPosition();
  }

  appStore.loading = false;
}

const currentSearchChanged = debounce(async function(currentSearchValue: string){
  appStore.loading = true

  if (currentSearchValue.length === 0) {
    appStore.loading = false;
    return;
  }

  try {
    const response = await data.autocompleteService.getPlacePredictionsFromGoogle(currentSearchValue);
    foundedResults.value = response;
  } catch {
    // empty
  }

  appStore.loading = false;
}, 600);

async function initMap() {
  try {
    const zoomIn = document.getElementById("map--zoom-in");
    const zoomOut = document.getElementById("map--zoom-out");
    const overview = document.getElementById("overview");

    const mapInstance = await createMap(
      mapNode.value as HTMLElement,
      {
        zoomIn: zoomIn,
        zoomOut: zoomOut,
        overview: overview,
        mapToggle: mapToggle
      }
    );
    data.map = mapInstance.map;
    data.google = mapInstance.google;
    data.placesService = new PlacesService(data.google);
    data.autocompleteService = new AutocompleteService(data.google);
    await trySetAddressCoords();
  } catch (error) {
    console.error(error);
  }
}

function point2LatLng(point: { x: number, y: number }) {
  const topRight = data.map.getProjection().fromLatLngToPoint(data.map.getBounds().getNorthEast());
  const bottomLeft = data.map.getProjection().fromLatLngToPoint(data.map.getBounds().getSouthWest());
  const scale = Math.pow(2, data.map.getZoom());
  const worldPoint = new data.google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
  return data.map.getProjection().fromPointToLatLng(worldPoint).toJSON();
}

function getRelativePos() {
  const parentPos: DOMRect = document.getElementsByClassName('map-page')[0].getBoundingClientRect();
  const floorplanTopLeftPointRectPos: DOMRect = document.getElementsByClassName('resize-handler tl')[0].getBoundingClientRect();
  const floorplanBottomRightPointRectPos: DOMRect = document.getElementsByClassName('resize-handler br')[0].getBoundingClientRect();
  return {
    topLeftRelativePos:
      {
        x: floorplanTopLeftPointRectPos.x - parentPos.x,
        y: floorplanTopLeftPointRectPos.y - parentPos.y
      },
    bottomRightRelativePos:
      {
        x: floorplanBottomRightPointRectPos.x - parentPos.x,
        y: floorplanBottomRightPointRectPos.y - parentPos.y
      }
  }
}
function calculateFloorplanCoords() {
  const floorplanRelativePos = getRelativePos();
  const topLeftCoords = point2LatLng(floorplanRelativePos.topLeftRelativePos);
  const bottomRightCoords = point2LatLng(floorplanRelativePos.bottomRightRelativePos);
  newFloorStore.$patch({
    topLeftCoordinates: { latitude: topLeftCoords.lat, longitude: topLeftCoords.lng },
    bottomRightCoordinates: { latitude: bottomRightCoords.lat, longitude: bottomRightCoords.lng }
  })
}

onUnmounted(() => {
  const containers = document.querySelectorAll('.pac-container');

  containers.forEach(container => {
    container.remove();
  });
})

</script>

<style lang="scss">
@import "@/styles/public/main.scss";
@function heightCalc($topBarHeight) {
  // 40px to count the map page's margin bottom and the spacing top
  @return calc(100vh - 40px - #{$topBarHeight} - 104px);
}
.page-title-1 {
  @include subtitle-1;
}

.map-page {
  display: flex;
  flex-direction: column;
  height: heightCalc(113px);
  overflow: hidden;
  &__map-element {
    position: relative;
    flex-grow: 1;

    .map-element {
      &__map-canvas {
        object-fit: contain;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      @mixin switcherShadow($image) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.31) 100%),
        url($image) center/contain no-repeat;
      }

      &__overview-container {
        position: absolute;
        cursor: pointer;
        left: 20px;
        bottom: 20px;
        width: 68px;
        height: 68px;
        background: white;
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
        border-radius: 4px;
        overflow: hidden;
        z-index: 1;

        .overview-container__image {
          position: absolute;
          border-radius: 4px;
          top: 2px;
          left: 2px;
          height: 64px;
          width: 64px;
          @include switcherShadow("../../../../assets/images/satellite.png");
        }

        &.mapToggle {
          background: $primary;

          .overview-container__image {
            @include switcherShadow("../../../../assets/images/map.png");
          }
        }

        .overview-container__text--font {
          position: absolute;
          bottom: 6px;
          @include counter;
          left: 50%;
          transform: translateX(-50%);
          color: white;
        }

        &:hover {
          .overview-container__image {
            top: 3px;
            left: 3px;
            height: 62px;
            width: 62px;
          }
        }
      }
    }
  }
  &--zoomControls {
    position: absolute;
    border-radius: 4px;
    bottom: 20px;
    right: 20px;
    display: grid;
    gap: 8px;
    z-index: 1;
  }

  .search {
    &-wrapper {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 20px;
    }
  }
}
</style>
