import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStateStore = defineStore(
  'authState',
  function() {
      const loggedIn = ref<boolean>(false);

    function loginSuccessful() {
      loggedIn.value = true;
    }

    return {
      loggedIn,
      loginSuccessful,
    }
  }
);